import { TABLET_BREAKPOINT, IPAD_BREAKPOINT, colors } from "@util/constants";
import { useSplitEventsByDate } from "@util/hooks";
import { Container, H1, StyledImage } from "@util/standard";
import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect } from "react";
import styled from "styled-components";
import PreviewArticle from "../components/news/PreviewArticle";
import Hero from "../components/shared/Hero";
import SEO from "../components/shared/Seo";

const Wrapper = styled.div<{ margin: string }>`
  width: 80%;
  ${({ margin }) => (margin ? `margin: ${margin};` : `margin: 0 auto`)};
`;

const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
  @media (max-width: ${IPAD_BREAKPOINT}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
`;

const StyledH2 = styled.h2`
  font-weight: 700;
  font-size: 24px;
  font-family: "extraBold";
  border-bottom: 1px solid #000;
  margin-bottom: 30px;
  padding-bottom: 10px;
  width: 30%;
`;

const EventsLandingPage = () => {
  const { sanityEventsLanding, allSanityEvents } = useStaticQuery(graphql`
    {
      sanityEventsLanding {
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityAboutHero
        }
        title
      }
      allSanityEvents {
        nodes {
          _id
          eventDate
          title
          shortDescription
          thumbnailImage {
            asset {
              fluid(maxWidth: 500) {
                srcSet
                srcSetWebp
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  `);

  const splitEventsByDate = useSplitEventsByDate(allSanityEvents.nodes);

  if (sanityEventsLanding == null) return null;

  return (
    <>
      <SEO seoData={sanityEventsLanding.seo} slug="events" />
      <Hero
        heroText={sanityEventsLanding.hero.heroText}
        heroImage={sanityEventsLanding.hero.heroImage}
        blockVisible
        blockTextOverwrite="EVENTS"
      />
      <Wrapper margin="80px auto 0px auto">
        {splitEventsByDate?.futureEvents &&
          splitEventsByDate.futureEvents.length > 0 && (
            <>
              <StyledH2>Upcoming Events</StyledH2>

              <ArticlesGrid>
                {splitEventsByDate.futureEvents.map(node => {
                  return <PreviewArticle event={node} key={node._id} />;
                })}
              </ArticlesGrid>
            </>
          )}
        <Container margin="20px auto" />
        {splitEventsByDate?.pastEvents &&
          splitEventsByDate.pastEvents.length > 0 && (
            <>
              <StyledH2>Previous Events</StyledH2>
              <ArticlesGrid>
                {splitEventsByDate.pastEvents.map(node => {
                  return <PreviewArticle event={node} key={node._id} />;
                })}
              </ArticlesGrid>
            </>
          )}
      </Wrapper>
    </>
  );
};

export default EventsLandingPage;
